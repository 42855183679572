<!-- @format -->

<template>
  <div>
    <canvas :id="chartId" class="px-4 py-4"></canvas>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, computed } from "vue";
import { Chart } from "chart.js";

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
});

const labelsData = computed(() => (props.data.length > 0 ? props.data[0] : {}));

const labels = computed(() => Object.keys(labelsData.value).map((key) => key));
const values = computed(() =>
  Object.values(labelsData.value).map((value) => value)
);

const data = {
  labels: labels.value.map((label) => {
    return label;
  }),
  datasets: [
    {
      data: values.value,
      backgroundColor: [
        "rgba(99, 171, 253, 1)",
        "rgba(113, 214, 206, 1)",
        "rgba(249, 155, 171, 1)",
        "rgba(255, 180, 79, 1)",
        "rgba(159, 151, 247, 1)",
      ],
      hoverOffset: 4,
    },
  ],
};

const config = {
  type: "doughnut",
  data: data,
  options: {
    aspectRatio: 12 / 10,
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    responsive: true,
    legend: {
      display: true,
      position: "right",
      labels: {
        padding: 20,
      },
    },
  },
};

const chartId = `chart-${Math.random().toString(36).substr(2, 9)}`;
const chartObj = ref(null);

onMounted(() => {
  chartObj.value = new Chart(document.getElementById(chartId), config);
});
</script>

<style scoped>
canvas {
  width: 100%;
  height: 100%;
}
</style>
