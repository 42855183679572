<!-- @format -->

<template>
  <div class="px-14 pt-12">
    <div>
      <canvas :id="chartId"></canvas>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, computed } from "vue";
import { Chart } from "chart.js";

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
});

const labelsData = computed(() => (props.data.length > 0 ? props.data[0] : {}));

const labels = computed(() => Object.keys(labelsData.value).map((key) => key));
const values = computed(() =>
  Object.values(labelsData.value).map((value) => `${value}`)
);

const backgroundColor = ref([
  "rgba(113, 214, 206, 1)",
  "rgba(99, 171, 253, 1)",
  "rgba(255, 180, 79, 1)",
  "rgba(159, 151, 247, 1)",
  "rgba(249, 155, 171, 1)",
  "rgba(206, 214, 222, 1)",
  "rgba(13, 127, 233, 1)",
  "rgba(237, 118, 51, 1)",
  "rgba(67, 126, 247, 1)",
  "rgba(55, 135, 156, 1)",
]);

const data = computed(() => ({
  labels: " ",
  datasets: labels.value.map((label, index) => ({
    label: label,
    data: values.value[index],
    backgroundColor: backgroundColor.value[index],
    barPercentage: 0.5,
    categoryPercentage: 1.0,
  })),
}));

const config = computed(() => ({
  type: "bar",
  data: data.value,
  options: {
    responsive: true,
    aspectRatio: 5 / 3,
    maintainAspectRation: false,
    legend: {
      display: true,
      position: "bottom",
      labels: {
        padding: 32,
        textAlign: "center",
      },
      align: "start",
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          display: true,
          drawOnChartArea: true,
        },
      },
      y: {
        grid: {
          display: true,
        },
        beginAtZero: true,
      },
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: true,
          },
        },
      ],
    },
  },
}));

const chartId = `chart-${Math.random().toString(36).substr(2, 9)}`;
const chartObj = ref(null);

onMounted(() => {
  chartObj.value = new Chart(
    document.getElementById(chartId).getContext("2d"),
    config.value
  );
});
</script>
