<!-- @format -->

<template>
  <div class="border-gray-100 border px-2 my-2 rounded flex items-center">
    <div
      v-for="(item, index) in items"
      :key="index"
      class="px-2 cursor-pointer transition duration-300 ease-in-out"
      :class="{
        'bg-teal-light bg-opacity-20 text-teal rounded':
          selectedIndex === index,
      }"
      @click="emitFilterChanged(index)"
    >
      {{ item }}
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from "vue";
const selectedIndex = ref(0);
const items = ref(["Daily", "Weekly", "Monthly", "Yearly"]);
const emit = defineEmits(["at-filter-changed"]);

const emitFilterChanged = (index) => {
  selectedIndex.value = index;
  switch (index) {
    case 0:
      emit("at-filter-changed", "daily");
      break;
    case 1:
      emit("at-filter-changed", "weekly");
      break;
    case 2:
      emit("at-filter-changed", "monthly");
      break;
    case 3:
      emit("at-filter-changed", "yearly");
      break;
  }
};
</script>
