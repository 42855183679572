<!-- @format -->

<template>
  <div class="relative bg-white rounded-lg border border-0">
    <div class="flex justify-between px-6 pt-6 pb-3">
      <div class="text-xl font-normal">Complaints List</div>
      <img
        class="cursor-pointer"
        src="../../../assets/icons/filter_icon.svg"
        @click="toggleFilters = !toggleFilters"
      />
    </div>
    <div class="drop-shadow-2xl w-full px-6 pb-2">
      <Tabs
        :bottomBorder="false"
        :key="tabIndex"
        :tabsData="tabsData"
        :activeTabIndex="tabIndex"
        @index="switchTab"
      />
    </div>
    <TableSkeletonLoader v-if="loader" :loader="loader" :cols="7" :rows="5" />
    <div v-else-if="!tableData?.data?.length">
      <div
        class="flex flex-col items-center justify-center text-center w-full py-10"
      >
        <div class="rounded-full p-12 icon-bg">
          <img src="../../../assets/icons/noData.svg" class="w-32" />
        </div>
        <div class="mt-4 text-teal font-medium flex align-middle">
          <i class="material-icons text-teal w-8">info_outlined</i>
          No Complaints Available
        </div>
      </div>
    </div>
    <ComplaintsListTable
      v-else
      :tableHeading="tableHeader"
      :tabIndex="tabIndex"
      :tableBody="tableData?.data"
      :isManager="isManager"
    />
    <Pagination
      :currentPage="tableData.current_page"
      :totalPages="tableData.last_page"
      @page-changed="atPageChange"
    />

    <transition name="slide-fade">
      <div
        v-if="toggleFilters"
        class="fixed inset-0 bg-black bg-opacity-30 z-40"
      >
        <div class="absolute inset-0 flex items-center justify-end z-50">
          <div
            class="bg-white p-6 max-w-lg w-1/3 h-full relative overflow-scroll"
            @blur="toggleFilters = true"
          >
            <button
              @click="toggleFilters = false"
              class="absolute top-2 right-2"
            >
              <span class="material-icons">close</span>
            </button>
            <ComplaintsMultiSelectFilters
              :filtersApplied="filtersApplied"
              :status="tabIndex"
              @at-filter-select="emitFilters"
              @at-clear-filters="emitClear"
              @at-filter-removal="emitRemoveFilter"
              @at-search-applied="emitSearchQuery"
              @at-status-change="updateStatus"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, computed, defineProps, toRefs, defineEmits } from "vue";
import { useRoute } from "vue-router";
import ComplaintsListTable from "@/components/newUi/tables/ComplaintsListTable.vue";
import TableSkeletonLoader from "@/components/newUi/atoms/TableSkeletonLoader.vue";
import Pagination from "@/components/newUi/atoms/Pagination.vue";
import Tabs from "@/components/newUi/baseComponents/Tabs.vue";
import ComplaintsMultiSelectFilters from "./ComplaintsMultiSelectFilters.vue";

const props = defineProps({
  tableData: {
    type: Array,
  },
  loader: {
    type: Boolean,
  },
  filtersApplied: {
    type: Object,
    default: () => {},
  },
});
const { tableData, loader, filtersApplied } = toRefs(props);
const emit = defineEmits([
  "at-tab-changed",
  "at-page-changed",
  "at-filter-select",
  "at-clear-filters",
  "at-remove-filter",
  "at-search-applied",
  "at-remove-search",
]);
const route = useRoute();
const isManager = ref(parseInt(route.params.isManager));
const toggleFilters = ref(false);

const tableHeader = computed(() => {
  switch (tabIndex.value) {
    case 0:
      return [
        "Patient Name",
        "Ref No.",
        "Subject Area",
        "Staff Group",
        "Practice",
        "Recieving Date",
        "Patient Age Group",
        "Details",
      ];
    case 1:
      return [
        "Patient Name",
        "Ref No.",
        "Subject Area",
        "Staff Group",
        "Practice",
        "Acknowledge Date",
        "Due Date",
        "Remaining Days",
        "Details",
      ];
    case 2:
    case 3:
    case 4:
      return [
        "Patient Name",
        "Ref No.",
        "Subject Area",
        "Staff Group",
        "Practice",
        "Concluding Date",
        "Details",
      ];
    default:
      return [
        "Patient Name",
        "Ref No.",
        "Subject Area",
        "Staff Group",
        "Practice",
        "Recieving Date",
        "Patient Age Group",
        "Details",
      ];
  }
});

const tabIndex = ref(0);

const switchTab = (index) => {
  tabIndex.value = index;
  emit("at-tab-changed", tabIndex.value);
  emit("at-clear-filters");
};

const emitFilters = (filters) => {
  emit("at-filter-select", filters);
};

const emitClear = () => {
  emit("at-clear-filters");
};

const emitRemoveFilter = (filter) => {
  emit("at-remove-filter", filter);
};

const emitSearchQuery = (searchQuery) => {
  emit("at-search-applied", searchQuery);
};

const updateStatus = (status) => {
  switchTab(status);
};

const tabsData = ref([
  {
    name: "Open Complaints",
    value: 0,
  },
  {
    name: "In Progress",
    value: 1,
  },
  {
    name: "Upheld",
    value: 2,
  },
  {
    name: "Partially Upheld",
    value: 3,
  },
  {
    name: "Not Upheld",
    value: 4,
  },
]);

const atPageChange = (page) => {
  if (page > tableData.last_page) {
    return;
  } else {
    emit("at-page-changed", page);
  }
};
</script>

<style scoped>
.material-icons {
  transition: transform 0.2s;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
