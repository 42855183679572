<!-- @format -->

<template>
  <div class="max-w-md mx-auto p-4 pt-6">
    <div
      v-if="filterLoader"
      class="bg-white flex items-center justify-center h-screen"
    >
      <svg
        class="text-gray-100 animate-spin"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
      >
        <path
          d="M32 3C35.8083 3 39.5794 3.75011 43.0978 5.20749C46.6163 6.66488 49.8132 8.80101 52.5061 11.4939C55.199 14.1868 57.3351 17.3837 58.7925 20.9022C60.2499 24.4206 61 28.1917 61 32C61 35.8083 60.2499 39.5794 58.7925 43.0978C57.3351 46.6163 55.199 49.8132 52.5061 52.5061C49.8132 55.199 46.6163 57.3351 43.0978 58.7925C39.5794 60.2499 35.8083 61 32 61C28.1917 61 24.4206 60.2499 20.9022 58.7925C17.3837 57.3351 14.1868 55.199 11.4939 52.5061C8.801 49.8132 6.66487 46.6163 5.20749 43.0978C3.7501 39.5794 3 35.8083 3 32C3 28.1917 3.75011 24.4206 5.2075 20.9022C6.66489 17.3837 8.80101 14.1868 11.4939 11.4939C14.1868 8.80099 17.3838 6.66487 20.9022 5.20749C24.4206 3.7501 28.1917 3 32 3L32 3Z"
          stroke="currentColor"
          stroke-width="5"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
        <path
          d="M32 3C36.5778 3 41.0906 4.08374 45.1692 6.16256C49.2477 8.24138 52.7762 11.2562 55.466 14.9605C58.1558 18.6647 59.9304 22.9531 60.6448 27.4748C61.3591 31.9965 60.9928 36.6232 59.5759 40.9762"
          stroke="currentColor"
          stroke-width="5"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="text-teal"
        ></path>
      </svg>
    </div>
    <div v-else>
      <div class="flex justify-between">
        <h2 class="text-2xl font-bold mb-4">Filters</h2>
        <button
          class="border border-teal text-teal px-2 h-8 rounded text-sm hover:bg-teal hover:text-white"
          @click="clearFilter()"
        >
          Clear All
        </button>
      </div>
      <div class="flex flex-wrap mb-4">
        <div class="w-1/2 py-3">
          <label for="search" class="block mb-2 text-sm font-medium"
            >Search</label
          >
          <input
            type="text"
            id="search"
            v-model="searchQuery"
            @input="searchApplied"
            class="w-full p-2 text-sm text-gray-700 border rounded bg-teal bg-opacity-10 focus:bg-white"
            placeholder="Search"
          />
        </div>
        <div class="w-1/2 p-3">
          <label for="status" class="block mb-2 text-sm font-medium"
            >Select Status</label
          >
          <v-select
            class="w-full"
            label="status"
            placeholder="Status"
            v-model="statusValue"
            :searchable="false"
            :clearable="false"
            :options="statusOptions"
            @update:modelValue="statusValueChanges"
          ></v-select>
        </div>
      </div>
      <small class="font-medium mb-2">Selected Items</small>

      <div class="flex flex-wrap mb-4">
        <div
          v-for="(filter, index) in selectedFilters"
          :key="index"
          class="bg-teal px-3 py-1 text-sm text-white rounded-xl mr-2 mb-2 flex items-center"
        >
          {{ filter.name }}
          <span class="cursor-pointer ml-2" @click="removeFilter(index)"
            >&times;</span
          >
        </div>
      </div>
      <div class="mb-4">
        <div class="flex justify-between">
          <h3 class="text-lg font-medium">Subject Area</h3>
          <span
            class="text-black cursor-pointer"
            @click="toggleGroup('subjectArea')"
          >
            <span
              class="material-icons"
              :class="
                isGroupOpen('subjectArea')
                  ? 'bg-teal rounded text-white'
                  : 'bg-teal bg-opacity-20 rounded'
              "
            >
              {{ isGroupOpen("subjectArea") ? "expand_more" : "expand_less" }}
            </span>
          </span>
        </div>
        <transition name="slide-fade">
          <div
            v-if="isGroupOpen('subjectArea')"
            class="flex-1 whitespace-pre my-2 h-32 overflow-y-auto"
          >
            <div class="flex flex-wrap">
              <div
                v-for="(filter, index) in subjectFilters"
                :key="index"
                class="bg-teal bg-opacity-20 px-3 py-1 text-sm text-gray-700 rounded-xl mr-2 mb-2 cursor-pointer"
                :class="{
                  'border-teal border-2  bg-opacity-30': isSelected(
                    filter.name
                  ),
                }"
                @click="toggleFilter(filter.id, 'Subject Area', filter)"
              >
                {{ filter.name }}
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="mb-4">
        <div class="flex justify-between">
          <h3 class="text-lg font-medium">Staff Group</h3>
          <span class="text-black cursor-pointer" @click="toggleGroup('staff')">
            <span
              class="material-icons"
              :class="
                isGroupOpen('staff')
                  ? 'bg-teal rounded text-white'
                  : 'bg-teal bg-opacity-20 rounded'
              "
            >
              {{ isGroupOpen("staff") ? "expand_more" : "expand_less" }}
            </span>
          </span>
        </div>
        <transition name="slide-fade">
          <div
            v-if="isGroupOpen('staff')"
            class="flex-1 whitespace-pre my-2 h-32 overflow-x-auto w-6/8"
          >
            <div class="flex flex-wrap">
              <div
                v-for="(filter, index) in staffFilters"
                :key="index"
                class="bg-teal bg-opacity-20 px-3 py-1 text-sm text-gray-700 rounded-xl mr-2 mb-2 cursor-pointer"
                :class="{
                  'border-teal border-2  bg-opacity-30': isSelected(
                    filter.name
                  ),
                }"
                @click="toggleFilter(filter.id, 'Staff Group', filter)"
              >
                {{ filter.name }}
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="mb-4" v-if="!isManager">
        <div class="flex justify-between">
          <h3 class="text-lg font-medium">Practice</h3>
          <span
            class="text-black cursor-pointer"
            @click="toggleGroup('practice')"
          >
            <span
              class="material-icons"
              :class="
                isGroupOpen('practice')
                  ? 'bg-teal rounded text-white'
                  : 'bg-teal bg-opacity-20 rounded'
              "
            >
              {{ isGroupOpen("practice") ? "expand_more" : "expand_less " }}
            </span>
          </span>
        </div>
        <transition name="slide-fade">
          <div
            v-if="isGroupOpen('practice')"
            class="flex-1 whitespace-pre my-2 h-32 overflow-x-auto w-6/8"
          >
            <div class="flex flex-wrap">
              <div
                v-for="(filter, index) in practiceFilters"
                :key="index"
                class="bg-teal bg-opacity-20 px-3 py-1 text-sm text-gray-700 rounded-xl mr-2 mb-2 cursor-pointer"
                :class="{
                  'border-teal border-2  bg-opacity-30': isSelected(
                    filter.practice_name
                  ),
                }"
                @click="
                  toggleFilter(filter.id, 'Practice', {
                    name: filter.practice_name,
                  })
                "
              >
                {{ filter.practice_name }}
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="mb-4">
        <div class="flex justify-between">
          <h3 class="text-lg font-medium">Patient Age Group</h3>
          <span
            class="text-black cursor-pointer"
            @click="toggleGroup('patient')"
          >
            <span
              class="material-icons"
              :class="
                isGroupOpen('patient')
                  ? 'bg-teal rounded text-white'
                  : 'bg-teal bg-opacity-20 rounded'
              "
            >
              {{ isGroupOpen("patient") ? "expand_more" : "expand_less" }}
            </span>
          </span>
        </div>
        <transition name="slide-fade">
          <div
            v-if="isGroupOpen('patient')"
            class="flex-1 whitespace-pre my-2 h-32 overflow-x-auto w-6/8"
          >
            <div class="flex flex-wrap">
              <div
                v-for="(filter, index) in patientFilters"
                :key="index"
                class="bg-teal bg-opacity-20 px-3 py-1 text-sm text-gray-700 rounded-xl mr-2 mb-2 cursor-pointer"
                :class="{
                  'border-teal border-2  bg-opacity-30': isSelected(filter),
                }"
                @click="
                  toggleFilter(filter, 'Patient Age Group', {
                    name: filter,
                  })
                "
              >
                {{ filter }}
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="mb-4">
        <div class="flex justify-between">
          <h3 class="text-lg font-medium">Date</h3>
          <span class="text-black cursor-pointer" @click="toggleGroup('date')">
            <span
              class="material-icons"
              :class="
                isGroupOpen('date')
                  ? 'bg-teal rounded text-white'
                  : 'bg-teal bg-opacity-20 rounded'
              "
            >
              {{ isGroupOpen("date") ? "expand_more" : "expand_less" }}
            </span>
          </span>
        </div>
        <transition name="slide-fade">
          <div v-if="isGroupOpen('date')" class="my-2">
            <div class="flex items-center gap-2">
              <DatePicker
                :selectedDate="filterStartDate"
                @selected-date="atfilterStartDate"
                @cleared-date="atfilterStartDate"
                :needMaxDate="true"
                :maxDate="date"
                :isDisabled="filterEndDate"
                placeholder="From"
                class="w-full border border-gray-200 rounded"
              />

              <DatePicker
                :selectedDate="filterEndDate"
                @selected-date="atfilterEndDate"
                @cleared-date="atfilterEndDate"
                :needMaxDate="true"
                :isDisabled="filterEndDate ? filterEndDate : !filterStartDate"
                :maxDate="filterEndDate ? formatDate(filterEndDate) : date"
                :needMinDate="filterStartDate ? true : false"
                :minDate="
                  filterEndDate
                    ? formatDate(filterEndDate)
                    : formatDate(filterStartDate)
                "
                placeholder="To"
                class="w-full border border-gray-200 rounded"
              />
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineEmits, defineProps, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import DatePicker from "@/components/newUi/atoms/DatePicker.vue";
const store = useStore();

const props = defineProps({
  filtersApplied: {
    type: Object,
  },
  status: {
    type: Number,
  },
});
const route = useRoute();
const isManager = ref(parseInt(route.params.isManager));
const filterLoader = ref(false);

const filtersApplied = ref(props.filtersApplied);
const searchQuery = ref("");
const statusOptions = ref([
  { key: 0, status: "Open" },
  { key: 1, status: "In-Progress" },
  { key: 2, status: "Upheld" },
  { key: 3, status: "Partially Upheld" },
  { key: 4, status: "Not Upheld" },
]);

const statusValue = ref(
  statusOptions.value.find((option) => option.key === props.status)
);

const emit = defineEmits([
  "at-filter-select",
  "at-filter-removal",
  "at-clear-filters",
  "at-search-applied",
  "at-status-change",
]);

const statusValueChanges = (status) => {
  clearFilter();
  emit("at-status-change", status.key);
};
const subjectFilters = ref([]);
const staffFilters = ref([]);
const practiceFilters = ref([]);
const patientFilters = ref(["Age 0-19", "Age 20-59", "Age 60+", "Age Unknown"]);

const date = new Date();
const filterStartDate = ref();
const filterEndDate = ref();

const formatDate = (date) => {
  const d = new Date(date);
  return d;
};

const atfilterStartDate = (selectedDate) => {
  if (selectedDate) {
    filterStartDate.value = selectedDate;
  } else {
    atfilterEndDate(null);
  }
};

const atfilterEndDate = (selectedDate) => {
  if (selectedDate) {
    filterEndDate.value = selectedDate;

    const startDateStr = new Date(filterStartDate.value).toLocaleDateString();
    const endDateStr = new Date(filterEndDate.value).toLocaleDateString();

    selectedFilters.value.push({
      name: `Date: ${startDateStr} -- ${endDateStr}`,
    });
    emit("at-filter-select", {
      startDate: filterStartDate.value,
      endDate: filterEndDate.value,
      filterKey: "Date",
    });
  } else {
    const startDateStr = new Date(filterStartDate.value).toLocaleDateString();
    const endDateStr = new Date(filterEndDate.value).toLocaleDateString();
    const dateFilterIndex = selectedFilters.value.findIndex(
      (filter) => filter.name == `Date: ${startDateStr} -- ${endDateStr}`
    );

    filterStartDate.value = null;
    filterEndDate.value = null;
    if (dateFilterIndex !== -1) {
      selectedFilters.value.splice(dateFilterIndex, 1);
    }
  }
};
const debounceTimer = ref(null);
const selectedFilters = ref([]);
const openGroups = ref({
  subjectArea: false,
  staff: false,
  practice: false,
  patient: false,
  date: false,
});

const searchApplied = () => {
  clearTimeout(debounceTimer.value);
  debounceTimer.value = setTimeout(() => {
    emit("at-search-applied", searchQuery.value);
  }, 800);
};

const isGroupOpen = (group) => openGroups.value[group];

const toggleGroup = (group) => {
  openGroups.value[group] = !openGroups.value[group];
};

const isSelected = (filterName) => {
  return (
    selectedFilters.value.findIndex(
      (selectedFilter) => selectedFilter.name === filterName
    ) !== -1
  );
};

const toggleFilter = (filterId, filterKey, filterItem) => {
  const index = selectedFilters.value.findIndex(
    (selectedFilter) => selectedFilter.name === filterItem.name
  );
  if (index !== -1) {
    emit("at-filter-removal", { filterId: filterId, filterKey: filterKey });
    removeFilter(index);
  } else {
    selectedFilters.value.push({
      name: filterItem.name,
      key: filterKey,
      id: filterId,
    });
    emit("at-filter-select", { filterId, filterKey, filterItem });
  }
};

const removeFilter = (index) => {
  const filter = selectedFilters.value[index];
  const startDateStr = new Date(filterStartDate.value).toLocaleDateString();
  const endDateStr = new Date(filterEndDate.value).toLocaleDateString();

  if (filter.name.includes(`Date: ${startDateStr} -- ${endDateStr}`)) {
    filterEndDate.value = null;
    filterStartDate.value = null;
    emit("at-filter-removal", { filterId: null, filterKey: "Date" });
  } else {
    emit("at-filter-removal", { filterId: filter.id, filterKey: filter.key });
  }
  selectedFilters.value.splice(index, 1);
};

const clearFilter = () => {
  selectedFilters.value = [];
  searchQuery.value = "";
  filterEndDate.value = null;
  filterStartDate.value = null;
  openGroups.value["subjectArea"] = false;
  openGroups.value["staff"] = false;
  openGroups.value["practice"] = false;
  openGroups.value["patient"] = false;
  openGroups.value["date"] = false;
  emit("at-clear-filters");
};

const addAppliedFilters = () => {
  filterLoader.value = true;
  if (filtersApplied.value.search) {
    searchQuery.value = filtersApplied.value.search;
  }
  if (filtersApplied.value.isFilter) {
    if (
      filtersApplied.value.filters.subjectAreaFilters &&
      Array.isArray(filtersApplied.value.filters.subjectAreaFilters)
    ) {
      filtersApplied.value.filters.subjectAreaFilters.forEach((id) => {
        const filter = subjectFilters.value.find((f) => f.id === id);
        if (filter) {
          selectedFilters.value.push({
            name: filter.name,
            key: "Subject Area",
            id: filter.id,
          });
        }
      });
    }

    if (
      filtersApplied.value.filters.staffGroupFilters &&
      Array.isArray(filtersApplied.value.filters.staffGroupFilters)
    ) {
      filtersApplied.value.filters.staffGroupFilters.forEach((id) => {
        const filter = staffFilters.value.find((f) => f.id === id);
        if (filter) {
          selectedFilters.value.push({
            name: filter.name,
            key: "Staff Group",
            id: filter.id,
          });
        }
      });
    }
    if (!isManager.value) {
      if (
        filtersApplied.value.filters.practiceFilters &&
        Array.isArray(filtersApplied.value.filters.practiceFilters)
      ) {
        filtersApplied.value.filters.practiceFilters.forEach((id) => {
          const filter = practiceFilters.value.find((f) => f.id === id);
          if (filter) {
            selectedFilters.value.push({
              name: filter.practice_name,
              key: "Practice",
              id: filter.id,
            });
          }
        });
      }
    }

    if (
      filtersApplied.value.filters.patientAgeGroupFilters &&
      Array.isArray(filtersApplied.value.filters.patientAgeGroupFilters)
    ) {
      filtersApplied.value.filters.patientAgeGroupFilters.forEach(
        (ageGroup) => {
          selectedFilters.value.push({
            name: ageGroup.includes("Age 60") ? "Age 60+" : ageGroup,
            key: "Patient Age Group",
            id: ageGroup,
          });
        }
      );
    }

    if (
      filtersApplied.value.filters.dateFilters &&
      Array.isArray(filtersApplied.value.filters.dateFilters) &&
      filtersApplied.value.filters.dateFilters.length > 0
    ) {
      const { startDate, endDate } =
        filtersApplied.value.filters.dateFilters[0];
      const startDateStr = new Date(startDate).toLocaleDateString();
      const endDateStr = new Date(endDate).toLocaleDateString();

      filterStartDate.value = new Date(startDate);
      filterEndDate.value = new Date(endDate);

      selectedFilters.value.push({
        name: `Date: ${startDateStr} -- ${endDateStr}`,
      });
    }
  }
};
const combinedFilters = computed(() => {
  const filters = {
    staff: staffFilters.value,
    subject: subjectFilters.value,
  };

  if (!isManager.value) {
    filters.practice = practiceFilters.value;
  }
  return filters;
});
watch(
  combinedFilters,
  (newFilters) => {
    const { staff, subject, practice } = newFilters;
    if (
      staff.length > 0 &&
      subject.length > 0 &&
      (!isManager.value ? practice && practice.length > 0 : true)
    ) {
      addAppliedFilters();
    }
  },
  { deep: true }
);
onMounted(async () => {
  filterLoader.value = true;
  if (isManager.value) {
    await store.dispatch("maComplaints/loadStaffGroup").then((response) => {
      staffFilters.value = response;
    });
    await store.dispatch("maComplaints/loadSubjectArea").then((response) => {
      subjectFilters.value = response;
    });
  } else {
    await store.dispatch("hqComplaints/loadStaffGroup").then((response) => {
      staffFilters.value = response;
    });
    await store.dispatch("hqComplaints/loadSubjectArea").then((response) => {
      subjectFilters.value = response;
    });
    await store.dispatch("hqComplaints/loadPractices").then((response) => {
      practiceFilters.value = response;
    });
  }
  filterLoader.value = false;
});
</script>

<style scoped>
.material-icons {
  transition: transform 0.2s;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

::v-deep .vs__dropdown-toggle {
  padding-left: 3px;
  padding-top: 3px;
  padding-bottom: 4px;
  background-color: rgba(0, 128, 128, 0.132);
  cursor: pointer !important;
  border: 1 rgb(196, 196, 196);
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
}
</style>
