<!-- @format -->

<template>
  <div class="pt-2 rounded-lg bg-white shadow-sm">
    <div class="pt-6 pb-10 px-8 gap-1 flex justify-between border-b">
      <div>
        <div class="text-md text-gray-400 font-semibold">Staff Groups</div>
        <div class="text-4xl font-medium pt-2">{{ staffGroupCount }}</div>
      </div>
      <ComplaintsDurationFilter @at-filter-changed="emitFilterChange" />
    </div>
    <div class="px-4 py-8">
      <BarChartComplaints
        :data="props?.staffGroupStats"
        :key="props.staffGroupStats"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";
import ComplaintsDurationFilter from "@/components/newUi/atoms/ComplaintsDurationFilter.vue";
import BarChartComplaints from "../charts/BarChartComplaints.vue";

const props = defineProps({
  staffGroupStats: {
    type: Array,
  },
});

const staffGroupCount = computed(() => {
  return Object.keys(props?.staffGroupStats[0]).reduce(
    (total, key) => total + props?.staffGroupStats[0][key],
    0
  );
});

const emit = defineEmits(["at-filter-change"]);

const emitFilterChange = (filter) => {
  emit("at-filter-change", filter);
};
</script>
