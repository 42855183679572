<!-- @format -->

<template>
  <div>
    <canvas :id="chartId" class="w-full px-10 py-5"></canvas>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, computed } from "vue";
import { Chart } from "chart.js";

const props = defineProps({
  data: {
    type: Array,
  },
});

const labelsData = computed(() => (props.data.length > 0 ? props.data[0] : {}));

const labels = computed(() => Object.keys(labelsData.value).map((key) => key));
const values = computed(() =>
  Object.values(labelsData.value).map((value) => (value > 0 ? `${value}` : 0))
);

const config = {
  type: "horizontalBar",
  data: {
    labels: labels.value.map((label) => {
      return label;
    }),
    datasets: [
      {
        axis: "y",
        data: values.value,

        backgroundColor: "rgba(99, 171, 253, 1)",
        borderColor: "rgba(22, 91, 170, 1)",
        borderWidth: 1,
        barPercentage: 0.3,
      },
    ],
  },
  options: {
    aspectRatio: 7 / 3,
    indexAxis: "y",
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  },
};

const chartId = `chart-${Math.random().toString(36).substr(2, 9)}`;
const chartObj = ref(null);

onMounted(() => {
  chartObj.value = new Chart(document.getElementById(chartId), config);
});
</script>
