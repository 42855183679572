<!-- @format -->

<template>
  <div class="pt-2 rounded-lg bg-white shadow-sm">
    <div class="p-6 gap-1">
      <div class="text-md text-gray-400 font-semibold">Subject Group</div>
      <div class="text-4xl font-medium">{{ subjectCount }}</div>
      <ComplaintsDurationFilter
        class="p-1 inline-flex"
        @at-filter-changed="emitFilterChange"
      />
    </div>
    <div class="h-520 overflow-scroll">
      <div
        v-for="(count, subject) in subjectList"
        :key="subject"
        class="py-4 px-6 border-t"
      >
        {{ subject }}
        <span
          class="bg-blue bg-opacity-10 text-blue px-2 rounded-md float-right"
          >{{ count }}</span
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, defineEmits } from "vue";
import ComplaintsDurationFilter from "@/components/newUi/atoms/ComplaintsDurationFilter.vue";

const props = defineProps({
  subjectList: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["at-filter-change"]);

const emitFilterChange = (filter) => {
  emit("at-filter-change", filter);
};

const subjectCount = computed(() => {
  return Object.keys(props.subjectList).reduce(
    (total, key) => total + props.subjectList[key],
    0
  );
});
</script>
