<!-- @format -->

<template>
  <div class="">
    <svg class="w-20 h-20" viewBox="0 0 100 100">
      <circle
        class="stroke-current opacity-20"
        :style="`color:` + props.progressBarColor"
        stroke-width="10"
        cx="50"
        cy="50"
        r="40"
        fill="transparent"
      ></circle>
      <circle
        class="progress-ring__circle stroke-current"
        :style="`color:` + props.progressBarColor"
        stroke-width="10"
        stroke-linecap="round"
        cx="50"
        cy="50"
        r="40"
        fill="transparent"
        stroke-dasharray="251.2"
        :stroke-dashoffset="strokeDashoffset"
      ></circle>
      <text
        v-if="hasCenterText"
        x="50%"
        y="50%"
        text-anchor="middle"
        dy=".3em"
        :style="`fill:` + props.progressBarColor"
        class="text-xl font-semibold"
      >
        {{ props.total }}
      </text>
    </svg>
  </div>
</template>

<script setup>
import { computed, defineProps } from "vue";

const props = defineProps({
  progress: {
    type: Number,
    default: 0,
  },
  total: {
    type: Number,
    default: 0,
  },
  progressBarColor: {
    type: String,
    default: "text-indigo-500",
  },
  hasCenterText: {
    type: Boolean,
    default: false,
  },
});

const strokeDashoffset = computed(() => {
  const circumference = 2 * Math.PI * 40;
  return (
    circumference -
    (circumference * props.progress) / (props.total ? props.total : 10)
  );
});
</script>

<style scoped>
/* Adjust the font size and other text styles here if necessary */
</style>
